//rfce
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, DatePicker } from "antd";
import { dateFormatIG } from "../IGconfig";

const dayjs = require("dayjs");

const DateRange = ({ value: range, onChange: setRange }) => {
    const handlePreviousMonthClick = () => {
        const [startDate, endDate] = range;
        const previousMonthStartDate = dayjs(startDate)
            .subtract(1, "month")
            .startOf("month");
        const previousMonthEndDate = dayjs(startDate)
            .subtract(1, "month")
            .endOf("month");
        setRange(
            [previousMonthStartDate, previousMonthEndDate],
            [
                previousMonthStartDate.format(dateFormatIG),
                previousMonthEndDate.format(dateFormatIG),
            ]
        );
    };

    const handleNextMonthClick = () => {
        const [startDate, endDate] = range;
        const nextMonthStartDate = dayjs(endDate)
            .add(1, "month")
            .startOf("month");
        const nextMonthEndDate = dayjs(endDate)
            .add(1, "month")
            .endOf("month");
        setRange(
            [nextMonthStartDate, nextMonthEndDate],
            [
                nextMonthStartDate.format(dateFormatIG),
                nextMonthEndDate.format(dateFormatIG),
            ]
        );
    };

    const handleSetCurrentMonthClick = () => {
        const currentDate = dayjs();
        const startDate = currentDate.startOf("month");
        const endDate = currentDate.endOf("month");
        setRange(
            [startDate, endDate],
            [startDate.format(dateFormatIG), endDate.format(dateFormatIG)]
        );
    };

    const handleSetPreviousMonthClick = () => {
        const currentDate = dayjs();
        const startDate = currentDate.subtract(1, "month").startOf("month");
        const endDate = currentDate.subtract(1, "month").endOf("month");
        setRange(
            [startDate, endDate],
            [startDate.format(dateFormatIG), endDate.format(dateFormatIG)]
        );
    };

    return (
        <div className="flex items-center gap-2">
            <DatePicker.RangePicker
                value={range}
                allowClear={false}
                onChange={(dates, dateStrings) => {
                    setRange(dates, dateStrings);
                }}
            />
            <Button onClick={handlePreviousMonthClick}>
                <LeftOutlined />
            </Button>
            <Button onClick={handleNextMonthClick}>
                <RightOutlined />
            </Button>
            <Button onClick={handleSetCurrentMonthClick}>
                Current Month
            </Button>
            <Button onClick={handleSetPreviousMonthClick}>
                Previous Month
            </Button>
        </div>
    );
};

export default DateRange;
