import { Input, Spin } from 'antd';
import useFetchEntities from 'hooks/useFetchEntities';
import useFetchEntity from 'hooks/useFetchEntity';
import { useMemo } from 'react';
import PhaseSelector from './Filters/PhaseSelector';
import CompetitionLogo from './Parts/CompetitionLogo';
import IGImg from './Parts/IGImg';

import "@fontsource/audiowide";
import "@fontsource/bebas-neue";
import "@fontsource/covered-by-your-grace";
import "@fontsource/economica";
import "@fontsource/righteous";
import "@fontsource/russo-one";

import { Content } from 'antd/es/layout/layout';
import DebugPrinter from 'components/Debug/DebugPrinter';
import SizeSelector from 'components/Infographics/Filters/SizeSelector';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import usePhaseIdTranslator from 'hooks/usePhaseIdTranslator';
import usePersistentStore from 'store/persistentStore';
import { formatDateToLongFormat, formatTimeStringToHHMM } from 'utils/IGdateTimeFunctions';
import DateRange from './Filters/DateRange';
import { dateFormatIG } from './IGconfig';
import GameCardTransparent from './Parts/GameCardTransparent';
import MonthHeader from './Parts/HeaderMonth';
dayjs.extend(customParseFormat);

const IG18_TopPlaysMostPenalties = ({ showData, exportRef }) => {
  const membership = usePersistentStore((state) => state.get('ig-membership'));
  const setMembership = usePersistentStore((state) => state.set('ig-membership'));
  const bgPictureUrlA = usePersistentStore((state) => state.get('ig-toplayspenalties-bgA-url'));
  const setBgPictureUrlA = usePersistentStore((state) => state.set('ig-toplayspenalties-bgA-url'));
  const bgPictureUrlB = usePersistentStore((state) => state.get('ig-toplayspenalties-bgB-url'));
  const setBgPictureUrlB = usePersistentStore((state) => state.set('ig-toplayspenalties-bgB-url'));

  const dateRange = usePersistentStore((state) => state.get('ig-topplays-daterange') ?? [dayjs().startOf('month'), dayjs().endOf('month')]);
  const setDateRange = usePersistentStore((state) => state.set('ig-topplays-daterange'));

  const size = usePersistentStore((state) => state.get('ig-toplayspenalties-size'));
  const setSize = usePersistentStore((state) => state.set('ig-toplayspenalties-size'));
  const SIZE_PRESETS = ['PORTRAIT', 'CUSTOM'];
  const DEFAULT_SIZE = { width: 1100, height: 1955, preset: 'PORTRAIT' };


  const groupId = membership?.groupId;
  const seasonId = membership?.seasonId;

  const { phaseIdsAll } = usePhaseIdTranslator(membership);

  const { data: groupDetail } = useFetchEntity('Group', groupId, 'Season>Competition');
  const { data: seasonDetail } = useFetchEntity('Season', seasonId, 'Competition');



  /*
  
 https://api.dev.hms.wootera.net/admin/entities?entity=Game
 &relations=Venue(name),HomeTeam(nick,name,logoUrl),AwayTeam(nick,name,logoUrl),HomeTeamStars>Player(firstName,lastName),AwayTeamStars>Player(firstName,lastName),HomeTeamGoalies>Player(firstName,lastName),AwayTeamGoalies>Player(firstName,lastName),Phase(name),Phase>Group(name),Phase>Group>Season(name),Phase>Group>Season>Competition(name)
 &extraAttributes=HomeTeamGoals,AwayTeamGoals,HomeTeamPenalties,AwayTeamPenalties,HomeTeamPenaltyMinutes,AwayTeamPenaltyMinutes,HomeTeamSaves,AwayTeamSaves,HomeTeamFaceOffs,AwayTeamFaceOffs
 &onlyAttributes=gameId,startDate,startTime,overwriteEnabled,timekeeper,referee,name&search=&organizationId="BB669217-41EB-4162-8040-A73F49A43570"
   
   */


  const { data: gamesAllInclStars, isLoading } = useFetchEntities(
    dateRange != null && phaseIdsAll != null && 'Game',
    {
      filters: {
        phaseId: phaseIdsAll,
        startDate: (dateRange != null && dateRange[0] != null && dateRange[1] != null) ?
          [`>=${dateRange[0]}`, `<=${dateRange[1]}`] : undefined
      },
      // Venue(name, logoUrl)
      relations: 'Venue(name,logoUrl),HomeTeam(nick,name,logoUrl),AwayTeam(nick,name,logoUrl),HomeTeamStars>Player(firstName,lastName),AwayTeamStars>Player(firstName,lastName),HomeTeamGoalies>Player(firstName,lastName),AwayTeamGoalies>Player(firstName,lastName),Phase(name),Phase>Group(name,color),Phase>Group>Season(name),Phase>Group>Season>Competition(name)',
      extraAttributes: 'HomeTeamGoals,AwayTeamGoals,HomeTeamPenalties,AwayTeamPenalties,HomeTeamPenaltyMinutes,AwayTeamPenaltyMinutes,HomeTeamSaves,AwayTeamSaves,HomeTeamFaceOffs,AwayTeamFaceOffs',
      onlyAttributes: 'gameId,startDate,startTime,overwriteEnabled,timekeeper,referee,name'
    }
  );

  // prepare metadata

  const metadata =
    useMemo(() => {
      return {
        GroupName: groupDetail?.name,
        GroupColor: groupDetail?.color,
        SeasonName: seasonDetail?.name,
        CompetitionName: seasonDetail?.Competition?.name,
        CompetitionLogo:
          seasonDetail?.Competition?.logoUrl?.replace(
            "[size]",
            "cropped_md",
          ),
        Date: dateRange?.[0] //dayjs().format(dateFormatIG), //TODO
      };
    }, [groupDetail, seasonDetail, dateRange]);

  // prepare data

  const data = useMemo(() => {
    return gamesAllInclStars
      ?.map((g) => ({
        VenueLogo: g.Venue?.logoUrl?.replace("[size]", "cropped_md"),
        HomeTeamLogo: g.HomeTeam?.logoUrl?.replace("[size]", "cropped_md"),
        AwayTeamLogo: g.AwayTeam?.logoUrl?.replace("[size]", "cropped_md"),
        TotalPenaltyMinutes:
          g.HomeTeamPenaltyMinutes + g.AwayTeamPenaltyMinutes,
        Date: g.startDate,
        Time: g.startTime,
        HomeTeamStarFirstName: g.HomeTeamStars?.[0]?.Player?.firstName,
        HomeTeamStarLastName: g.HomeTeamStars?.[0]?.Player?.lastName,
        AwayTeamStarFirstName: g.AwayTeamStars?.[0]?.Player?.firstName,
        AwayTeamStarLastName: g.AwayTeamStars?.[0]?.Player?.lastName,
        Referee: g.referee,
        Score: `${g.HomeTeamGoals}:${g.AwayTeamGoals}`,
      }))
      .sort((a, b) => b.TotalPenaltyMinutes - a.TotalPenaltyMinutes)
      .slice(0, 10);
  }, [gamesAllInclStars]) ?? [];


  return (
    <>
      <div className="grid grid-cols-[auto,1fr] gap-3 items-center">

        <span>Membership:</span>
        <PhaseSelector
          value={membership}
          onChange={setMembership}
          mandatory={['competitionId', 'seasonId']}
        />

        <span>Choose period:</span>
        <div>
          <DateRange
            onChange={(_, dateStrings) => { setDateRange(dateStrings) }}
            value={[dayjs(dateRange?.[0], dateFormatIG), dayjs(dateRange?.[1], dateFormatIG)]}>
          </DateRange>
        </div>

        <span>Picture url [right]:</span>
        <Input value={bgPictureUrlA} onChange={(e) => setBgPictureUrlA(e.target.value)} />

        <span>Picture url [bottom]:</span>
        <Input value={bgPictureUrlB} onChange={(e) => setBgPictureUrlB(e.target.value)} />

        <span>Dimensions:</span>
        <div className="flex gap-2 items-center">
          <SizeSelector
            value={size}
            onChange={setSize}
            presets={SIZE_PRESETS}
            defaultValue={DEFAULT_SIZE}
          />
        </div>

        <div />
      </div>

      {isLoading &&
        <Content className="flex items-center justify-center">
          <Spin />
        </Content>
      }

      {showData && (<DebugPrinter data={[metadata, data, gamesAllInclStars]}></DebugPrinter>)}

      {phaseIdsAll && gamesAllInclStars &&
        <article
          ref={exportRef}
          style={{
            width: `${size?.width}px`,
            height: `${size?.height}px`,
          }}
          className="relative mx-auto min-h-[1000px]  w-[800px] overflow-hidden"
        >
          <div className="relative h-full grid grid-cols-5 bg-hockey-page-bg/95 font-header text-white z-[2]">
            <div className="col-span-3 flex flex-col p-8 pr-0">
              {/* TODO: Replace title string with a variable */}
              <MonthHeader
                className="border-b-2 border-white"
                title="Most penalties games"
                date={metadata.Date}
                league={metadata.GroupName} //"KLASIK divize"
                leagueStyle={{ color: metadata.GroupColor ?? '#777777' }}
              //leagueClassName="text-orange-400"
              />
              <div className="flex h-full flex-col items-center justify-between">
                <div className="flex flex-col gap-2">
                  {data.map(
                    (game, index) =>
                      index < 10 && (
                        <GameCardTransparent
                          key={index}
                          game={game}
                          textUnder={`${formatDateToLongFormat(game.Date)}, ${formatTimeStringToHHMM(game.Time)}, Result: ${game.Score}, Ref: ${game.Referee}`}
                        >
                          {game.TotalPenaltyMinutes}
                        </GameCardTransparent>
                      ),
                  )}
                </div>
                <CompetitionLogo
                  className="mb-8 mt-12"
                  src={metadata.CompetitionLogo}
                  title={metadata.CompetitionName}
                  season={metadata.SeasonName}
                />
              </div>
            </div>
            {/* Absolute images behind */}
            <IGImg
              src={bgPictureUrlA}
              //src="https://wallup.net/wp-content/uploads/2015/12/235412-nature-landscape-reflection-lake-fall-forest-mist-sunrise-mountain-trees-Canada-clear_sky-sunlight.jpg"
              alt=""
              className="col-span-2 h-[1000px] w-full object-cover"
              style={{
                clipPath: "polygon(50px 0, 100% 0, 100% 100%, 0% 100%)",
              }}
            />
            <IGImg
              src="/images/most-penalties-games.png"
              className="absolute -bottom-16 right-32 z-20 h-auto w-[650px]"
              alt=""
            />
          </div>
          <IGImg
            src={bgPictureUrlB}
            // src="https://upload.wikimedia.org/wikipedia/commons/3/39/Pittsburgh_Penguins%2C_Washington_Capitals%2C_Bryan_Rust_%2833744033514%29.jpg"
            className="absolute bottom-0 h-auto w-full"
            alt=""
          />
        </article>}
    </>

  );
};

export default IG18_TopPlaysMostPenalties;
