import React, { useMemo } from "react";
import BaseTableRows from "./BaseTableRows";
import GraphWLAgainstLargestSum from "./GraphWLAgainstLargestSum";

const TableScoringEfficiency = ({ data }) => {
  // count needed for the graph
  const maxGFPlusChances = useMemo(() => {
    let max = 0;
    for (const team of data) {
      let sum = team.Chances + team.GF;
      if (sum > max) {
        max = sum;
      }
    }
    return max;
  }, [data]);

  return (
    <table className="text-center font-bold">
      <thead>
        <tr className="border-b text-sm">
          <th colSpan="3" />
          <th className="text-base">GP</th>
          <th className="text-base text-green-600">GF</th>
          <th className="text-green-200">Chances</th>
          <th className="text-xs text-green-200">Chances per game</th>
          <th className="border-x border-black bg-white px-1 text-xs text-green-600">
            Goals/ Chances %
          </th>
          <th className="px-1 text-xs">
            Goals fired VS. Goals allowed comparison
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((team, index) => (
          <tr key={index}>
            <BaseTableRows index={index} icon={team.Logo} name={team.Name} />
            <td className="px-3">{team.GP}</td>
            <td className="px-3 text-green-600">{team.GF}</td>
            <td className="px-3 text-green-200">{team.Chances}</td>
            <td className="px-3 text-green-200">{team.ChancesPerGame}</td>
            <td className="border-x border-black px-3 text-green-600">
              {team.GoalsFromChances}
            </td>
            <td>
              <GraphWLAgainstLargestSum
                largestSum={maxGFPlusChances}
                W={team.GF}
                L={team.Chances}
                lClassName="!bg-green-200"
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableScoringEfficiency;
