import { AppstoreAddOutlined, TableOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Checkbox, Divider, Input, Popover, Radio, Spin, Table, Tooltip } from 'antd';
import ErrorIcon from 'components/ErrorIcon';
import ExportButton from 'components/ExportButton';
import useIsMobile from 'hooks/useIsMobile';
import useSearchParamsMulti from 'hooks/useSearchParamsMulti';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useGlobalStore from 'store/globalStore';
import exportTableToCsv from 'utils/exportTableToCsv';
import extractTextFromJSX from 'utils/extractTextFromJsx';
import { removeAccents } from 'utils/oneLiners';
import { simulateCtrlClick } from 'utils/simulateCtrlClick';

const TableBody = ({ isMobileVersion, filteredData, getMobileDataSorted, onRowClickedHandler, renderMobileRow, emptyPlaceholder, tableRef, fixedX, finalFixedY, className, finalColumns, onChange, sortState, currentPage, paginationNeeded, onRowClicked, onRowClickedLink, size, rowSelection, rowKey }) => {
    const pageSize = useGlobalStore(state => state.hmsTablePageSize);
    const setPageSize = useGlobalStore(state => state.setHmsTablePageSize);

    return (
        isMobileVersion ?
            <>
                {filteredData && filteredData.length > 0 &&
                    <div className="flex flex-col gap-2 px-2 py-2">
                        {filteredData && getMobileDataSorted(filteredData).map((record, idx) => (
                            <div
                                key={idx}
                                onClick={(e) => onRowClickedHandler(record, e)}
                                className="cursor-pointer border-b-[1px] border-gray-100 rounded-md">
                                {renderMobileRow(record)}
                            </div>
                        ))}
                    </div>
                }
                {(!filteredData || filteredData.length == 0) &&
                    <div className="py-12 text-gray-400">
                        {emptyPlaceholder}
                    </div>
                }
            </>
            :
            <div className="overflow-auto"> { /* Added to fix scrolling in relation tables */}
                <Table
                    ref={tableRef}

                    size={size}

                    scroll={{
                        scrollToFirstRowOnChange: true,
                        x: fixedX ? fixedX : undefined,
                        y: finalFixedY,
                    }}

                    className={`w-full ${className}`}
                    showSorterTooltip={false}
                    columns={finalColumns.filter(c => c.visible !== false && c.isHidden !== true)}
                    dataSource={filteredData}
                    onChange={onChange}

                    sorter={{
                        columnKey: sortState.columnKey,  // use columnKey here
                        order: sortState.order
                    }}

                    pagination={
                        !paginationNeeded ? false :
                            {
                                position: ['bottomCenter'],
                                pageSize,
                                current: currentPage,
                                showSizeChanger: true,
                                onShowSizeChange: (current, newSize) => {
                                    setPageSize(newSize);
                                    localStorage.setItem('HMSTable_PageSize', newSize);
                                },
                                pageSizeOptions: ['50', '100', '250', '500']
                            }
                    }

                    locale={{ emptyText: emptyPlaceholder }}
                    onRow={(record) => ({
                        onClick: (event) => onRowClickedHandler(record, event),
                    })}
                    rowClassName={() => (onRowClicked || onRowClickedLink) ? 'hover:cursor-pointer' : ''}
                    rowSelection={rowSelection}
                    rowKey={rowKey}
                />
            </div>
    );
}

const Buttons = ({ createButtonLink, isMobile, createButtonType, buttons, exportEnabled, exportClicked, data }) => (
    <>
        {createButtonLink &&
            <div>
                <Link to={createButtonLink}>
                    <Button size={isMobile ? 'small' : 'small'} type={createButtonType} icon={<AppstoreAddOutlined />}>
                        Create
                    </Button>
                </Link>
            </div>
        }
        {buttons && buttons.map((b, idx) => (
            <Button key={idx} size={isMobile ? 'small' : 'small'} type={b.type} icon={b.icon} onClick={b.onClick} disabled={b.disabled} >
                {b.label}
            </Button>
        ))}
    </>
);

const ColumnsSelector = ({ finalColumns, toggleHiddenColumn, toggleAllHiddenColumns }) => {
    let hiddenColumns = 0;
    let unHiddenColumns = 0;
    for (const c of finalColumns) {
        if (c.visible !== false) {
            if (c.isHidden === true) {
                hiddenColumns++;
            } else {
                unHiddenColumns++;
            }
        }
    }

    return (
        <Popover
            content={
                <div className="min-w-[120px]"
                >
                    <div className="hover:bg-blue-50 p-[2px] px-2 cursor-pointer" onClick={(e) => { toggleAllHiddenColumns(); e.preventDefault(); }}>
                        <Checkbox className="select-none" indeterminate={hiddenColumns > 0 && unHiddenColumns > 0} checked={hiddenColumns == 0}>Toggle all</Checkbox>
                    </div>

                    <Divider className="my-1" />

                    {finalColumns.filter(c => c.visible !== false).map((c, idx) => (
                        <div className="hover:bg-blue-50 p-[2px] px-2 cursor-pointer" key={idx} onClick={(e) => { toggleHiddenColumn(c); e.preventDefault(); }}>
                            <Checkbox checked={!c.isHidden}>{c.title}</Checkbox>
                        </div>
                    ))}
                </div>
            }
            placement="bottom"
            trigger="click"
        >
            <Badge count={0 - hiddenColumns}>
                <Button icon={<TableOutlined />} size="small">Columns</Button>
            </Badge>
        </Popover>
    )
}

const AllButtons = ({ isMobile, title, createButtonLink, buttons, createButtonType, exportEnabled, exportClicked, data, extraButtons, filterGroups, getEffectiveFilterGroupSelections, onFilterChange, columnSets, columnSet, setColumnSet, extraButtonBarItems, finalColumns, toggleHiddenColumn, toggleAllHiddenColumns, columnSelectorEnabled, filteredData }) => {

    const computeLabel = (item) => { return isMobile ? (item.shortLabel ?? item.label) : item.label; }

    return (
        <div className={`flex flex-wrap gap-y-3 gap-x-3 items-center font-normal ${isMobile ? 'mt-1' : ''} max-w-full`}>

            {/* Buttons */}

            {title && (createButtonLink || (buttons && buttons.length > 0)) &&
                <div className="hidden desktop:inline-block h-5 border-l-1" style={{ borderLeft: '1px solid gray' }} />
            }

            <Buttons {...{ createButtonLink, isMobile, createButtonType, buttons, exportEnabled, exportClicked, data }} />

            {columnSelectorEnabled &&
                <ColumnsSelector {...{ finalColumns, toggleHiddenColumn, toggleAllHiddenColumns }} />
            }

            {extraButtons}

            {exportEnabled &&
                <div>
                    <ExportButton exportClicked={exportClicked} disabled={!data} />
                </div>
            }

            {/* Filters */}
            {filterGroups && filterGroups.length > 0 && <>
                <div className="hidden desktop:inline-block h-5 border-l-1" style={{ borderLeft: '1px solid gray' }} />

                {filterGroups.map((group, groupIdx) => (
                    <span
                        key={groupIdx}
                        className="max-w-full whitespace-normal"
                    >
                        <Radio.Group
                            size={isMobile ? 'small' : 'small'}
                            value={getEffectiveFilterGroupSelections()[groupIdx]}
                            onChange={(e) => onFilterChange(groupIdx, e)}
                        >
                            {group.map((item, itemIdx) => (
                                <Radio.Button
                                    key={itemIdx}
                                    value={item.label}
                                >
                                    {computeLabel(item)}
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </span>
                ))}
            </>}

            {columnSets && columnSets.length > 0 &&
                <>
                    <div className="hidden desktop:inline-block h-5 border-l-1" style={{ borderLeft: '1px solid gray' }} />

                    <Radio.Group
                        size={isMobile ? 'small' : 'small'}
                        value={columnSet}
                        onChange={(e) => setColumnSet(e.target.value)}
                    >
                        {columnSets.map((cs, idx) => (
                            <Radio.Button
                                key={idx}
                                value={idx}
                            >
                                {cs.name}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </>
            }

            {extraButtonBarItems && <>
                <div className="hidden desktop:inline-block h-5 border-l-1" style={{ borderLeft: '1px solid gray' }} />
                {typeof extraButtonBarItems == 'function' ? extraButtonBarItems({ filteredData }) : extraButtonBarItems}
            </>}

        </div>
    )
};

function LoadingAndSearch({ isMobile, filteredData, search, setSearch }) {
    return (
        <span className="ml-auto flex items-center gap-3">
            {!isMobile && filteredData &&
                <span className="whitespace-nowrap">
                    Count: <span className="font-bold">{filteredData?.length?.toLocaleString() ?? 0}</span>
                </span>
            }
            {!isMobile && !filteredData &&
                <span className="text-gray-400">Loading...</span>
            }
            <Input
                className="no-hightlight-on-focus"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                allowClear={true}
            />
        </span>
    )
}

const HmsTable = forwardRef(({ layoutType = 'card', className, fixedX: fixedXParam, fixedY, buttonBarFooter, title, buttons, extraButtons, emptyMessage = 'No data', createButtonLink, createButtonType, columns: columnsParam, columnSets, filterGroups, onFiltersChanged, onRowClicked, onRowClickedLink, onSearchChangedParam, data, isError, persistentId, renderMobileRow, exportEnabled, exportPrefix, extraButtonBarItems, columnSelectorEnabled, size = 'large', rowSelection, rowKey }, ref) => {
    const navigate = useNavigate();
    const [urlParamPrefix] = useState(layoutType == 'plain' ? `${Math.random().toString(36).substring(7)}-` : '');
    const [indexedData, setIndexedData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const { useSearchParamSingle } = useSearchParamsMulti();
    const [filterGroupSelections, setFilterGroupSelections] = useSearchParamSingle('filterGroupSelections', (filterGroups ?? []).map(group => (group.find(i => i.isDefault) ?? group[0]).label).join(','), { type: 'string' });
    const [currentPage, setCurrentPage] = useSearchParamSingle(urlParamPrefix + 'currentPage', 1, { type: 'int' });
    const [columnSet, setColumnSet] = useSearchParamSingle(urlParamPrefix + 'columnSet', 0, { type: 'int' });
    const [search, setSearch] = useSearchParamSingle(urlParamPrefix + 'search', '');
    const [hiddenColumns, setHiddenColumns] = useSearchParamSingle(urlParamPrefix + 'hiddenColumns', '');
    const isMobile = useIsMobile();
    const isMobileVersion = isMobile && renderMobileRow;

    useImperativeHandle(ref, () => ({
        exportToCsv: () => {
            exportClicked();
        }
    }));

    const toggleHiddenColumn = (c) => {
        const hiddenColumnsArr = JSON.parse((hiddenColumns != '' ? hiddenColumns : null) ?? '[]');
        const title = extractTextFromJSX(c.title);
        if (hiddenColumnsArr.indexOf(title) != -1) {
            hiddenColumnsArr.splice(hiddenColumnsArr.indexOf(title), 1);
        } else {
            hiddenColumnsArr.push(title);
        }
        setHiddenColumns(JSON.stringify(hiddenColumnsArr));
    }

    const toggleAllHiddenColumns = () => {
        const hiddenColumnsArr = JSON.parse((hiddenColumns != '' ? hiddenColumns : null) ?? '[]');
        if (hiddenColumnsArr.length == 0) {
            setHiddenColumns(JSON.stringify(finalColumns.filter(c => c.visible !== false).map(c => extractTextFromJSX(c.title))));
        } else {
            setHiddenColumns(JSON.stringify([]));
        }
    }

    const preparedColumns = (columnsParam ?? columnSets?.[columnSet]?.columns ?? [])
        .map((c, idx) => ({
            ...c,
            key: idx,
        }));

    let defaultSort = {
        columnKey: null,
        order: null,
    };

    const value = filterGroupSelections.split(',')?.[0];
    const selectedFilter = filterGroups?.[0]?.find(g => g.label == value);
    const preferredSort = selectedFilter?.preferredSort;
    if (preferredSort) {
        preparedColumns.some(c => {
            if (c.dataIndex == preferredSort.column || c.column == preferredSort.column) {
                defaultSort = {
                    columnKey: c.key,
                    order: preferredSort.direction ?? 'ascend',
                };
                return true;
            }
        });
    }
    if (defaultSort.columnKey == null) {
        preparedColumns.some(c => {
            if (c.defaultSortOrder) {
                defaultSort = {
                    columnKey: c.key,
                    order: c.defaultSortOrder,
                };
                return true;
            }
        });
    }


    const [sort, setSort] = useSearchParamSingle('sort', defaultSort.columnKey != null ? `${defaultSort.columnKey}-${defaultSort.order}` : '');

    // special handling for sort
    const [columnKey, order] = sort.split('-');
    const sortState = {
        columnKey: columnKey && columnKey != '' ? parseInt(columnKey) : null,
        order: order && order != '' ? order : null,
    }
    const setSortState = ({ columnKey, order }) => {
        setSort(`${columnKey}-${order}`);
    }

    const columns = preparedColumns
        .map((c, idx) => ({
            ...c,
            key: idx,
            sortOrder: sortState.columnKey === idx ? sortState.order : null,
            width: c.width ?? (fixedXParam ? 100 : undefined),       // default width
        }))

    const getEffectiveFilterGroupSelections = () => {
        // TODO: check if filters are valid
        return filterGroupSelections.split(',') ?? [];
    }

    const defaultSorter = (a, b) => {
        if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b);
        }
        if (typeof a === 'number' && typeof b === 'number') {
            return a - b;
        }
        console.log('Cannot sort:', typeof a, typeof b);
    }

    const finalColumns = columns
        .map(column =>
            column ?
                (
                    {
                        sorter: (a, b) => defaultSorter(a[column.dataIndex], b[column.dataIndex]),
                        sortDirections: ['ascend'],
                        ...column,
                        title: <span className='select-none truncate'>{column.titleTooltip ? <Tooltip title={column.titleTooltip}>{column.title}</Tooltip> : column.title}</span>
                    }
                )
                :
                (
                    {
                        title: "[Wrong column]",
                        render: () => "[Wrong column]",
                    }
                )
        )
        .map(column => ({
            ...column,
            isHidden: (JSON.parse((hiddenColumns != '' ? hiddenColumns : null) ?? '[]')).indexOf(extractTextFromJSX(column.title)) != -1,
        }))

    let fixedX = fixedXParam;
    if (fixedX) {
        let totalWidth = 0;
        if (columns) {
            for (const column of finalColumns) {
                if (column.visible !== false && column.isHidden !== true) {
                    totalWidth += column.width ?? 100;
                }
            }
        }
        fixedX = totalWidth;
    }

    useEffect(() => {
        const indexedData = data ? data
            .filter(d => {
                if (d == null) {
                    console.log('Warning: NULL data received in:', data);
                }
                return d != null;
            })
            .map(d => {
                let ftIndex = '';
                for (const col of finalColumns) {
                    if (col.searchValue) {
                        ftIndex += col.searchValue(d) + ' ';
                    } else if (col.render) {
                        const r = col.render(d);
                        if (typeof r == 'string') {
                            ftIndex += r + ' ';
                        } else {
                            const s = extractTextFromJSX(r);
                            ftIndex += s + ' ';
                        }
                    } else if (col.dataIndex) {
                        const r = d[col.dataIndex];
                        if (typeof r == 'string') {
                            ftIndex += r + ' ';
                        } else {
                            const s = extractTextFromJSX(r);
                            ftIndex += s + ' ';
                        }
                    } else {
                        console.log('Warning: No searchValue, render or dataIndex in column:', col);
                    }
                }
                ftIndex = removeAccents(ftIndex.toUpperCase());

                return {
                    ...d,
                    ftIndex
                };
            }) : null;
        setIndexedData(indexedData);
    }, [data, columnsParam]);

    useEffect(() => {
        if (indexedData) {
            const searchWords = removeAccents(search.trim().toUpperCase()).split(' ').map(w => w.trim());

            const fiteredData =
                ((search.trim() == '')
                    ? indexedData
                    : indexedData
                        .filter(d => {
                            for (const searchWord of searchWords) {
                                if (d.ftIndex.indexOf(searchWord) == -1) {
                                    return false;
                                }
                            }
                            return true;
                        })
                )
            setFilteredData(fiteredData[0]?.key ? fiteredData : fiteredData.map((r, idx) => ({ key: idx, ...r })));
        } else {
            setFilteredData(null);
        }
    }, [indexedData, search]);

    const tableRef = useRef(null);

    const emptyPlaceholder = filteredData
        ? <span className='h-6 flex items-center justify-center max-w-[calc(100vw-25px)] desktop:max-w-[calc(100vw-233px)]'>{emptyMessage}</span>
        : !isError
            ? <span className='h-6 flex items-center justify-center max-w-[calc(100vw-25px)] desktop:max-w-[calc(100vw-233px)]'><Spin delay={0}></Spin></span>
            : <span className='h-6 flex items-center justify-center max-w-[calc(100vw-25px)] desktop:max-w-[calc(100vw-233px)]'><ErrorIcon /></span>;

    const onChange = (pagination, filters, sorter, extra) => {
        if (pagination.current != currentPage) {
            setCurrentPage(pagination.current);
        }
        if (sorter.columnKey != sortState.columnKey || sorter.order != sortState.order) {
            setSortState({
                columnKey: sorter.columnKey,
                order: sorter.order
            });
            setCurrentPage(1);
        }
    };

    const onFilterChange = (idx, e) => {
        const newFilters = [...filterGroupSelections.split(',')];
        const value = e.target.value;
        newFilters[idx] = value;
        setFilterGroupSelections(newFilters.join(','));
        setCurrentPage(1);

        const selectedFilter = filterGroups?.[idx]?.find(g => g.label == value);
        const preferredSort = selectedFilter?.preferredSort;
        if (preferredSort) {
            columns.some(c => {
                if (c.dataIndex == preferredSort.column || c.column == preferredSort.column) {
                    setSortState({
                        columnKey: c.key,
                        order: preferredSort.direction ?? 'ascend',
                    })
                    return true;
                }
            });
        }
    };

    useEffect(() => {
        if (filterGroups) {
            let effectiveFilters = {};

            for (let i = 0; i < filterGroups.length; i++) {
                const group = filterGroups[i];
                const selectedLabel = filterGroupSelections.split(',')[i];
                const selected = group.find(g => g.label == selectedLabel);
                effectiveFilters = { ...effectiveFilters, ...selected.filters };
            }

            if (onFiltersChanged) {
                onFiltersChanged(effectiveFilters);
            }
        }
    }, [filterGroupSelections]);

    const onRowClickedHandler = (record, event) => {
        if (onRowClicked) {
            onRowClicked(record, event);
        }
        if (onRowClickedLink) {
            const isCtrlClick = event.metaKey || event.ctrlKey;
            const link = onRowClickedLink(record);
            if (isCtrlClick) {
                simulateCtrlClick(link);
            } else {
                navigate(link)
            }
        }
    };

    const exportClicked = (target = 'csv') => {
        let sortedData;
        const sortCol = finalColumns.find(c => c.key == sortState.columnKey);
        if (sortCol) {
            const sorter = sortCol.sorter;
            sortedData = [...filteredData].sort((a, b) => {
                return (sortState.order == 'descend' ? -1 : 1) * sorter(a, b);
            });
        } else {
            sortedData = filteredData;
        }
        const exportedColumns = finalColumns.filter(c => c.exported !== false && (c.visible !== false && c.isHidden !== true) || c.exported == true);

        exportTableToCsv(exportedColumns, sortedData, target, exportPrefix ?? title);
    };

    // Fixed header
    const [tablePosition, setTablePosition] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    useEffect(() => {
        if (fixedY) {
            if (!isMobileVersion) {
                const tablePosition = tableRef.current.getBoundingClientRect().top;
                setTablePosition(tablePosition);

                const headerCells = tableRef.current.querySelectorAll('.ant-table-thead th');
                let calculatedHeaderHeight = 0;
                headerCells.forEach(cell => {
                    calculatedHeaderHeight = Math.max(calculatedHeaderHeight, cell.offsetHeight);
                });
                setHeaderHeight(calculatedHeaderHeight);

                const handleResize = () => {
                    setViewportHeight(window.innerHeight);
                };

                // console.log('HmsTable - Register fixed header handler')
                window.addEventListener('resize', handleResize);

                return () => {
                    // console.log('HmsTable - Unregister fixed header handler')
                    window.removeEventListener('resize', handleResize);
                };
            }
        }
    }, [isMobileVersion, finalColumns]);

    const paginationNeeded = !(!filteredData || filteredData.length < 50);
    let finalFixedY = fixedY ? viewportHeight - tablePosition - headerHeight - (!paginationNeeded ? 35 : 98) - 0 : undefined;

    // let's make the view at least 1/2 of the viewport (in cases such as when filters take up more than a screen height)
    if (finalFixedY < viewportHeight / 2) {
        finalFixedY = viewportHeight / 2;
    }

    const getMobileDataSorted = (data) => {
        const sortedData = [...data];

        const preferredSort = selectedFilter?.preferredSort;
        if (preferredSort) {
            const sortColumn = finalColumns.find(c => c.column == preferredSort?.column);
            if (sortColumn) {
                const sorter = sortColumn?.sorter;
                if (sorter) {
                    sortedData.sort(sorter);
                    if (preferredSort.direction == 'descend') {
                        sortedData.reverse();
                    }
                }
            }
        }

        return sortedData;
    }

    switch (layoutType) {
        case 'card':
            return (
                <Card
                    size="small"
                    className={`${isMobileVersion ? 'custom-no-padding-card mb-10' : 'w-[calc(100vw-25px)] desktop:w-[calc(100vw-233px)] max-h-[calc(100vh-135px)] overflow-auto'}`}
                    title={
                        <>
                            <div className="flex flex-wrap my-2 gap-y-3 gap-x-4 items-center font-normal">

                                <div className="font-bold">
                                    {title}
                                    {/* {' '}({paginationNeeded ? 'page=yes' : 'page=no'},
                                {finalFixedY},
                                vph={viewportHeight},
                                tb={tablePosition},
                                hh={headerHeight}
                                ) */}
                                </div>

                                {!isMobile && <AllButtons {...{ isMobile, title, createButtonLink, buttons, createButtonType, exportEnabled, exportClicked, data, extraButtons, filterGroups, getEffectiveFilterGroupSelections, onFilterChange, columnSets, columnSet, setColumnSet, extraButtonBarItems, finalColumns, toggleHiddenColumn, toggleAllHiddenColumns, columnSelectorEnabled, filteredData }} />}

                                <LoadingAndSearch {...{ isMobile, filteredData, search, setSearch }} />

                            </div>
                            {isMobile &&
                                <div className="pb-3 font-normal flex flex-wrap gap-3">
                                    <AllButtons {...{ isMobile, title, createButtonLink, buttons, createButtonType, exportEnabled: !isMobileVersion && exportEnabled, exportClicked, data, extraButtons, filterGroups, getEffectiveFilterGroupSelections, onFilterChange, columnSets, columnSet, setColumnSet, extraButtonBarItems, finalColumns, toggleHiddenColumn, toggleAllHiddenColumns, columnSelectorEnabled: !isMobileVersion && columnSelectorEnabled, filteredData }} />
                                </div>
                            }

                            {buttonBarFooter}
                        </>
                    }
                >
                    <div className={`${isMobileVersion ? "" : "max-h-[calc(100vh-150px)] overflow-auto"}`}>
                        <TableBody
                            {...{ isMobileVersion, filteredData, getMobileDataSorted, onRowClickedHandler, renderMobileRow, emptyPlaceholder, tableRef, fixedX, finalFixedY, className, finalColumns, onChange, sortState, currentPage, paginationNeeded, onRowClicked, onRowClickedLink, size, rowSelection, rowKey }}
                        />
                    </div>
                </Card>
            );
        case 'plain':
            return (
                <div>
                    <div className="mb-4">
                        <div className="flex">
                            <AllButtons {...{ isMobile, title, createButtonLink, buttons, createButtonType, exportEnabled, exportClicked, data, extraButtons, filterGroups, getEffectiveFilterGroupSelections, onFilterChange, columnSets, columnSet, setColumnSet, extraButtonBarItems, finalColumns, toggleHiddenColumn, toggleAllHiddenColumns, columnSelectorEnabled, filteredData }} />

                            <LoadingAndSearch {...{ isMobile, filteredData, search, setSearch }} />

                        </div>

                        {buttonBarFooter}
                    </div>
                    <TableBody
                        {...{ isMobileVersion, filteredData, getMobileDataSorted, onRowClickedHandler, renderMobileRow, emptyPlaceholder, tableRef, fixedX, finalFixedY, className, finalColumns, onChange, sortState, currentPage, paginationNeeded, onRowClicked, onRowClickedLink, size, rowSelection, rowKey }}
                    />
                </div>
            );
    }
})

export default HmsTable
