import React from "react";

const GraphWLAgainstLargestSum = ({
  largestSum,
  W,
  L,
  className,
  wClassName,
  lClassName,
}) => {
  const goalsSum = W + L;
  /* min. 15px wide to be able to see something */
  const barWidth = 15 + 95 * (goalsSum / largestSum);

  const WPercentage = (W / goalsSum) * 100;
  const LPercentage = (L / goalsSum) * 100;

  return (
    <div
      className={`flex items-center ${className}`}
      style={{ width: `${barWidth}px` }}
    >
      <div
        className={`h-5 bg-green-600 ${wClassName}`}
        style={{ width: `${WPercentage}%` }}
      />
      <div
        className={`h-5 bg-red-600 ${lClassName}`}
        style={{ width: `${LPercentage}%` }}
      />
    </div>
  );
};

export default GraphWLAgainstLargestSum;
