import React, { useMemo } from "react";
import GraphWLAgainstLargestSum from "./GraphWLAgainstLargestSum";
import BaseTableRows from "./BaseTableRows";

const TableOffenceDefence = ({ data }) => {
  // count needed for the graph
  const largestSumGFplusGA = useMemo(() => {
    let maxSum = 0;
    for (const team of data) {
      const currentSum = team.GF + team.GA; // Calculate sum of goals for and against for the current team
      if (currentSum > maxSum) {
        maxSum = currentSum; // Update maxSum if current team's sum is greater
      }
    }
    return maxSum;
  }, [data]);

  return (
    <table className="text-center font-bold">
      <thead>
        <tr className="border-b">
          <th colSpan="3" />
          <th>GP</th>
          <th className="text-green-600">GF</th>
          <th className="text-red-500">GA</th>
          <th className="border-x border-black bg-white text-gray-500">DIFF</th>
          <th className="text-green-600">GF/GP</th>
          <th className="text-red-500">GA/GP</th>
          <th className="text-xs">Goals fired VS. Goals allowed comparison</th>
        </tr>
      </thead>
      <tbody>
        {data.map((team, index) => (
          <tr key={index}>
            <BaseTableRows index={index} icon={team.Logo} name={team.Name} />
            <td className="px-3">{team.GP}</td>
            <td className="px-3 text-green-600">{team.GF}</td>
            <td className="px-3 text-red-500">{team.GA}</td>
            <td
              className={`border-x border-black px-3 text-lg ${team.Diff > 0 ? "text-green-600" : "text-red-500"}`}
            >
              {team.Diff}
            </td>
            <td className="px-3 text-green-600">{team.GF_GP}</td>
            <td className="px-3 text-red-500">{team.GA_GP}</td>
            <td>
              <GraphWLAgainstLargestSum
                W={team.GF}
                L={team.GA}
                largestSum={largestSumGFplusGA}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableOffenceDefence;
