import { useEffect, useState } from "react";

const IGImgBase64 = ({ src, className, alt }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(src, {
          credentials: 'include',
        });
        const blob = await response.blob();

        const reader = new FileReader();
        reader.onloadend = () => {
          setImageSrc(reader.result);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

    fetchImage();
  }, []);

  return (
    <div>
      {imageSrc ? (
        <img src={imageSrc} className={className} alt={alt} />
      ) : (
        <p>Loading image...</p>
      )}
    </div>
  );
};

export default IGImgBase64;
