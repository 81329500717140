import { Select } from 'antd';
import useFetchEntities from 'hooks/useFetchEntities';
import useFetchEntity from 'hooks/useFetchEntity';

const { Option } = Select;

const TeamPlayerSelector = ({ value, onChange: setSelectedTeamPlayer }) => {

    const { data: teams, loading: teamsLoading } = useFetchEntities('Team', { onlyAttributes: ['teamId', 'name'] });
    let { data: teamWithPlayers, loading: playersLoading } =
        useFetchEntity(value?.teamId && 'Team', value?.teamId,
            'Players(playerId,firstName,lastName)',
            { onlyAttributes: ['teamId', 'name'] });


    const handleTeamChange = (v) => {
        setSelectedTeamPlayer({ teamId: v, playerId: null });
        teamWithPlayers = null;
    };

    const handlePlayerChange = (v) => {
        setSelectedTeamPlayer({ ...value, playerId: v });
    }

    return (
        <div>
            <Select
                placeholder="Select a team"
                showSearch
                optionFilterProp="label"
                style={{ width: 200, marginRight: 20 }}
                value={value?.teamId}
                onChange={handleTeamChange}
                loading={teamsLoading}
            >
                {teams?.map((team) => (
                    <Option key={team.teamId} value={team.teamId} label={team.name}>
                        {team.name}
                    </Option>
                ))}
            </Select>

            <Select
                placeholder="Select a player"
                style={{ width: 200 }}
                showSearch
                optionFilterProp="label"
                value={value?.playerId}
                onChange={handlePlayerChange}
                disabled={!value?.teamId || playersLoading}
                loading={playersLoading}
            >
                {teamWithPlayers?.Players?.map((player) => (
                    <Option key={player.playerId} value={player.playerId} label={player.lastName + ' ' + player.firstName}>
                        {player.lastName + ' ' + player.firstName}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default TeamPlayerSelector;