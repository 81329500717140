import { Select } from 'antd';
import useFetchEntities from 'hooks/useFetchEntities';

function PhaseSelector({ value, onChange, mandatory = [] }) {
    const { data: competitionData } = useFetchEntities('Competition', { relations: 'Seasons>Groups>Phases' });

    const competitionId = value?.competitionId;
    const seasonId = value?.seasonId;
    const groupId = value?.groupId;
    const phaseId = value?.phaseId;

    const handleCompetitionChange = (value) => {
        onChange({
            competitionId: value,
            seasonId: null,
            groupId: null,
            phaseId: null,
        });
    }

    const handleSeasonChange = (value) => {
        onChange({
            competitionId: competitionId,
            seasonId: value,
            groupId: null,
            phaseId: null,
        });
    }

    const handleGroupChange = (value) => {
        onChange({
            competitionId: competitionId,
            seasonId: seasonId,
            groupId: value,
            phaseId: null,
        });
    }

    const handlePhaseChange = (value) => {
        onChange({
            competitionId: competitionId,
            seasonId: seasonId,
            groupId: groupId,
            phaseId: value,
        });
    }

    const Placeholder = ({ isLoading, text, mandatory }) => (
        <span>
            {isLoading
                ?
                <span>Loading...</span>
                :
                <>
                    <span>{text}</span>
                    {mandatory && <span className="text-red-500"> *</span>}
                </>
            }
        </span>
    )

    return (
        <div className="grid grid-cols-4 gap-2">
            <Select
                options={competitionData?.map(c => ({
                    label: c.name,
                    value: c.competitionId,
                }))}
                value={competitionData && competitionId}
                disabled={!competitionData}
                onChange={handleCompetitionChange}
                placeholder={<Placeholder isLoading={!competitionData} text="Competition" mandatory={mandatory.includes('competitionId')} />}
                allowClear
            />

            <Select
                options={competitionData
                    ?.find(c => c.competitionId == competitionId)
                    ?.Seasons
                    ?.map(s => ({
                        label: s.name,
                        value: s.seasonId,
                    }))
                }
                value={competitionData && seasonId}
                disabled={!competitionData}
                onChange={handleSeasonChange}
                placeholder={<Placeholder isLoading={!competitionData} text="Season" mandatory={mandatory.includes('seasonId')} />}
                allowClear
            />

            <Select
                options={competitionData
                    ?.find(c => c.competitionId == competitionId)
                    ?.Seasons
                    ?.find(s => s.seasonId == seasonId)
                    ?.Groups
                    ?.map(g => ({
                        label: g.name,
                        value: g.groupId,
                    }))
                }
                value={competitionData && groupId}
                disabled={!competitionData}
                onChange={handleGroupChange}
                placeholder={<Placeholder isLoading={!competitionData} text="Group" mandatory={mandatory.includes('groupId')} />}
                allowClear
            />

            <Select
                options={competitionData
                    ?.find(c => c.competitionId == competitionId)
                    ?.Seasons
                    ?.find(s => s.seasonId == seasonId)
                    ?.Groups
                    ?.find(g => g.groupId == groupId)
                    ?.Phases
                    ?.map(p => ({
                        label: p.name,
                        value: p.phaseId,
                    }))
                }
                value={competitionData && phaseId}
                disabled={!competitionData}
                onChange={handlePhaseChange}
                placeholder={<Placeholder isLoading={!competitionData} text="Phase" mandatory={mandatory.includes('phaseId')} />}
                allowClear
            />
        </div>
    )
}

export default PhaseSelector