import { Input } from "antd";
import DebugPrinter from "components/Debug/DebugPrinter";
import SizeSelector from 'components/Infographics/Filters/SizeSelector';
import dayjs from 'dayjs';
import useFetchDataWithOrg from "hooks/useFetchDataWithOrg";
import useFetchEntity from "hooks/useFetchEntity";
import usePhaseIdTranslator from 'hooks/usePhaseIdTranslator';
import { useMemo } from "react";
import usePersistentStore from 'store/persistentStore';
import PhaseSelector from "./Filters/PhaseSelector";
import { dateFormatIG } from "./IGconfig";
import CompetitionLogo from "./Parts/CompetitionLogo";
import IGHeader from "./Parts/IGHeader";
import IGImg from "./Parts/IGImg";
import TablePlayers from "./Parts/TablePlayers";

const IG15_PenaltyLeaders = ({ showData, exportRef }) => {
  const membership = usePersistentStore((state) => state.get('ig-membership'));
  const setMembership = usePersistentStore((state) => state.set('ig-membership'));
  const bgPictureUrl = usePersistentStore((state) => state.get('ig-penaltyleaders-bg-url'));
  const setBgPictureUrl = usePersistentStore((state) => state.set('ig-penaltyleaders-bg-url'));
  const size = usePersistentStore((state) => state.get('ig-penaltyleaders-size'));
  const setSize = usePersistentStore((state) => state.set('ig-penaltyleaders-size'));
  const SIZE_PRESETS = ['PORTRAIT', 'CUSTOM'];
  const DEFAULT_SIZE = { width: 625, height: 1111, preset: 'PORTRAIT' };

  const groupId = membership?.groupId;
  const seasonId = membership?.seasonId;
  const { phaseIdsAll } = usePhaseIdTranslator(membership);

  const { data: groupDetail } = useFetchEntity('Group', groupId, 'Season>Competition');
  const { data: seasonDetail } = useFetchEntity('Season', seasonId, 'Competition');

  const playerStats = useFetchDataWithOrg(phaseIdsAll
    ? `/admin/tablePlayersStats?phaseIds=${phaseIdsAll.join(',')}`
    : null);

  const metadata = useMemo(() => {
    return {
      GroupName: groupDetail?.name,
      GroupColor: groupDetail?.color,
      SeasonName: seasonDetail?.name,
      CompetitionName: seasonDetail?.Competition?.name,
      CompetitionLogo:
        seasonDetail?.Competition?.logoUrl?.replace(
          "[size]",
          "cropped_md",
        ),
      Date: dayjs().format(dateFormatIG),
    };
  }, [groupDetail, seasonDetail]);

  // prepare data

  const data = useMemo(() => {
    const data = playerStats?.data?.map((player) => ({
      FirstName: player.FirstName,
      LastName: player.LastName,
      PlayerLogo: player.PlayerLogo?.replace("[size]", "cropped_md"),
      PlayerLevelCode: player.PlayerLevelCode,
      TeamLogo: player.TeamLogo?.replace("[size]", "cropped_md"),
      Z: player.GP,
      G: player.G,
      A: player.A,
      P: player.P,
      TM: player.PIM,
      Stars: player.Stars,
    })) ?? [];

    const sorted = data.sort((a, b) => {
      return b.TM - a.TM;
    });

    return sorted.slice(0, 15);
  }, [playerStats]);

  return (
    <>
      <div className="grid grid-cols-[auto,1fr] gap-3 items-center">

        <span>Membership:</span>
        <PhaseSelector
          value={membership}
          onChange={setMembership}
          mandatory={['competitionId', 'seasonId']}
        />

        <span>Background picture url:</span>
        <Input value={bgPictureUrl} onChange={(e) => setBgPictureUrl(e.target.value)} />

        <span>Dimensions:</span>
        <div className="flex gap-2 items-center">
          <SizeSelector
            value={size}
            onChange={setSize}
            presets={SIZE_PRESETS}
            defaultValue={DEFAULT_SIZE}
          />
        </div>

        <div />
      </div>

      {showData && (<DebugPrinter data={[groupDetail, playerStats, data, metadata]}></DebugPrinter>)}
      {playerStats && phaseIdsAll &&
        <article
          ref={exportRef}
          className="mx-auto w-[625px] bg-hockey-page-bg font-header text-white"
          style={{
            width: `${size?.width}px`,
            height: `${size?.height}px`,
          }}
        >
          <div className="p-8">
            <IGHeader
              title={`League leaders`}
              league={metadata.GroupName}
              leagueStyle={{ color: metadata.GroupColor ?? '#777777' }}
              small
              date={metadata.Date}
            />
            <TablePlayers data={data} highlightColID="tm" />
          </div>
          <div className="relative h-[80px]">
            <IGImg
              src="/images/stars-tracker.png"
              className="absolute top-0 z-10 -mt-14 h-[250px]"
              alt=""
            />
          </div>
          <IGImg
            src={bgPictureUrl}
            //src="https://wallup.net/wp-content/uploads/2015/12/235412-nature-landscape-reflection-lake-fall-forest-mist-sunrise-mountain-trees-Canada-clear_sky-sunlight.jpg"
            alt=""
            className="aspect-[24/10] w-full object-cover"
            style={{
              clipPath: "polygon(450px 0, 100% 0, 100% 100%, 0 100%, 0 70px)",
            }}
          />
          <CompetitionLogo
            className="mx-auto scale-75 p-4"
            src={metadata.CompetitionLogo}
            title={metadata.CompetitionName}
            season={metadata.SeasonName}
          />
        </article>
      }
    </>
  );
};

export default IG15_PenaltyLeaders;
