import AwardCompetitions from 'components/Award/AwardCompetitions';
import AwardGames from 'components/Award/AwardGames';
import AwardPeople from 'components/Award/AwardPeople';
import AwardPlayers from 'components/Award/AwardPlayers';
import AwardSponsors from 'components/Award/AwardSponsors';
import AwardTeams from 'components/Award/AwardTeams';
import AwardVenues from 'components/Award/AwardVenues';
import EntityAttachments from 'components/EntityAttachments';
import EntityLogoEditor from 'components/EntityLogoEditor';
import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import HmsField from 'components/Form/HmsField';
import HmsTabbedTables from 'components/HmsTabbedTables';
import useEntities from 'hooks/useEntities';
import useLoadEntity from 'hooks/useLoadEntity';

const Award = () => {
  const entities = useEntities();
  const entity = entities.Award;

  // Important: we need to separate loading of relations because otherwise the query is huuuuge and it consumes a lot of memory
  // Example:
  //    - these three queries on DEV on a sample award comsume 15 MBs of RAM
  //    - when in one query, it did consume over 600 MBs of RAM
  //
  // TODO: We should make the same optimization elsewhere in the system -- maybe make it somehow 'built in'
  const { id, isLoading, isError, isNotFound, data, reload, relationsData, reloadRelations } = useLoadEntity({
    entity,
    relations: [
      'People',
      'Players,Players>Teams,Players>ListPlayerLevel,Players>ListPositions',
      'Teams',
    ].join(',')
  });
  const { relationsData: relationsData2, reloadRelations: reloadRelations2 } = useLoadEntity({
    entity,
    relations: [
      'Venues',
      'Sponsors',
      'Competitions',
    ].join(',')
  });
  const { relationsData: gamesData, reloadRelations: reloadGames } = useLoadEntity({
    entity,
    relations: [
      'Games',
      'Games>HomeTeam',
      'Games>AwayTeam',
      'Games>Venue',
    ].join(','),
  });
  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({
    entity,
    relations: [
      'Attachments',
    ].join(',')
  });

  return (
    <>
      <HmsEntityFormController
        {... { entity, id, isLoading, isError, isNotFound, data, reload }}
        form={(
          <div className="flex flex-col desktop:flex-row w-full">
            <div className="flex items-center justify-center ml-4 mr-6 mb-5 desktop:mb-0 ">
              <EntityLogoEditor entity={entities.Award} id={id} data={data} size={112} />
            </div>
            <div className="flex-auto">
              <HmsEntityFormGridBasic>
                <HmsField name="name" />
                <HmsField name="nick" />
                <HmsField name="short" />
                <HmsField name="description" />
                <HmsField name="foundDate" />
                <HmsField name="type" />
              </HmsEntityFormGridBasic>
            </div>
          </div>
        )}
        footer={id != '_new_' && (
          <div className="mt-4">
            <div className="mt-4">
              <HmsTabbedTables
                tables={[
                  {
                    title: 'People' + (relationsData?.People ? ` (${relationsData['People'].length})` : ''),
                    children: <AwardPeople sourceEntity={entity} sourceEntityId={id} data={relationsData && relationsData['People']} onChange={reloadRelations} />,
                  },
                  {
                    title: 'Players' + (relationsData?.Players ? ` (${relationsData['Players'].length})` : ''),
                    children: <AwardPlayers sourceEntity={entity} sourceEntityId={id} data={relationsData && relationsData['Players']} onChange={reloadRelations} />,
                  },
                  {
                    title: 'Games' + (gamesData?.Games ? ` (${gamesData['Games'].length})` : ''),
                    children: <AwardGames sourceEntity={entity} sourceEntityId={id} data={gamesData && gamesData['Games']} onChange={reloadGames} />
                  },
                  {
                    title: 'Teams' + (relationsData?.Teams ? ` (${relationsData['Teams'].length})` : ''),
                    children: <AwardTeams sourceEntity={entity} sourceEntityId={id} data={relationsData && relationsData['Teams']} onChange={reloadRelations} />
                  },
                  {
                    title: 'Venues' + (relationsData2?.Venues ? ` (${relationsData2['Venues'].length})` : ''),
                    children: <AwardVenues sourceEntity={entity} sourceEntityId={id} data={relationsData2 && relationsData2['Venues']} onChange={reloadRelations2} />
                  },
                  {
                    title: 'Sponsors' + (relationsData2?.Sponsors ? ` (${relationsData2['Sponsors'].length})` : ''),
                    children: <AwardSponsors sourceEntity={entity} sourceEntityId={id} data={relationsData2 && relationsData2['Sponsors']} onChange={reloadRelations2} />
                  },
                  {
                    title: 'Competitions' + (relationsData2?.Competitions ? ` (${relationsData2['Competitions'].length})` : ''),
                    children: <AwardCompetitions sourceEntity={entity} sourceEntityId={id} data={relationsData2 && relationsData2['Competitions']} onChange={reloadRelations2} />
                  },
                  {
                    title: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                    children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                  },
                ]}
              />
            </div>
          </div>
        )}
      />
    </>
  );
}

export default Award
