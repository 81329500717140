import ApprovalBar from 'components/ApprovalBar';
import EntityAttachments from 'components/EntityAttachments';
import EntityChangeRequests from 'components/EntityChangeRequests';
import EntityLogo from 'components/EntityLogo';
import EntityLogoEditor from 'components/EntityLogoEditor';
import FormSelect from 'components/Form/FormSelect';
import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import HmsField from 'components/Form/HmsField';
import HmsTabbedTables from 'components/HmsTabbedTables';
import { ListContext } from 'components/ListsProvider';
import PlayerAwards from 'components/Player/PlayerAwards';
import PlayerDisciplinary from 'components/Player/PlayerDisciplinary';
import PlayerGames from 'components/Player/PlayerGames';
import PlayerJerseys from 'components/Player/PlayerJerseys';
import PlayerPositions from 'components/Player/PlayerPositions';
import PlayerSponsors from 'components/Player/PlayerSponsors';
import PlayerTeams from 'components/Player/PlayerTeams';
import PlayerLevelTag from 'components/PlayerLevelTag';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useEntities from 'hooks/useEntities';
import useFetchEntities from 'hooks/useFetchEntities';
import useLists from 'hooks/useLists';
import useLoadEntity from 'hooks/useLoadEntity';
import { useContext, useEffect, useMemo, useRef } from 'react';
import useLoginStore from 'store/loginStore';
dayjs.extend(utc);

const Player = () => {
  const userRoles = useLoginStore(s => s.roles);
  const isAdmin = userRoles?.indexOf('ADMIN') != -1;
  const entities = useEntities();
  const entity = entities.Player;
  const { id, isLoading, isReloading, isError, isNotFound, data, reload, relationsData, reloadRelations } = useLoadEntity({
    entity,
    relations: [
      'Teams',
      'Sponsors',
      'Awards',
      'Disciplinaries(subject,status),Disciplinaries>Game(startDate,startTime),Disciplinaries>Game>HomeTeam(short),Disciplinaries>Game>AwayTeam(short),Disciplinaries>Game>Venue(name)',
      'Jerseys>Team',
      'ListPositions',
    ].join(','),
  });
  const { data: gamesData } = useFetchEntities('Lineup', {
    filters: {
      playerId: id
    },
    extraAttributes: 'StatGoals,StatAssists,StatPoints,StatPenaltyMinutes,IsStar',
    relations: 'Game!(*,+HomeTeamGoals,+AwayTeamGoals),Game!>HomeTeam,Game!>AwayTeam,Game!>Venue,ListPosition(name)',
  });
  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({
    entity,
    relations: [
      'Attachments',
    ].join(',')
  });

  const { orgPreferences } = useContext(ListContext);

  const needsToSelectTeam = !isAdmin && !data?.status && orgPreferences.requiredFields?.includes("teamId");
  const { data: teamsData, isLoading: teamsDataLoading } = useFetchEntities(needsToSelectTeam && 'Team', { onlyAttributes: ['teamId', 'name', 'logoUrl'] });

  const ref = useRef();

  useEffect(() => {
    if (teamsDataLoading == false) {
      if (id == '_new_') {
        ref?.current?.setFieldValue('Teams', teamsData?.map(t => t.teamId));
      }
    }
  }, [teamsDataLoading]);

  // Change requests
  const { relationsData: changeRequestsData, reloadRelations: reloadChangeRequests } = useLoadEntity({
    entity,
    relations: [
      'ChangeRequests',
    ].join(',')
  });
  const unprocessedChangeRequestCount = useMemo(() => changeRequestsData?.ChangeRequests?.filter(r => r.status == 'NEW')?.length ?? 0, [changeRequestsData]);
  const showChangeRequestDot = isAdmin && unprocessedChangeRequestCount > 0;
  const isLocked = (data && data?.status != 'DRAFT') && !isAdmin;
  const canCreateChangeRequest = isLocked && !isAdmin;
  const { ListPlayerLevel } = useLists();
  const ListPlayerLevelWithIcons = ListPlayerLevel.map((l) => ({
    value: l[entities.ListPlayerLevel.primaryKey],
    label: <span className="flex gap-2 items-center"><PlayerLevelTag listPlayerLevel={l} /> {l.name}</span>
  }));
  const changeRequestFields = [
    { value: "listPlayerLevelId", label: 'Player level', type: 'select', options: ListPlayerLevelWithIcons },
    { value: 'firstName', },
    { value: "lastName", },
    { value: "birthday", },
    { value: "gender", },
    { value: "stick", },
    { value: "status", },
    { value: "height", },
    { value: "weight", },
    { value: "phone", },
    { value: "email", },
    { value: "countryBirth", },
    { value: "cityBirth", },
    { value: "country", },
    { value: "city", },
    { value: "publicProfileUrl", },
    { value: "AddTeam", label: 'Add Team' },
    { value: "RemoveTeam", label: 'Remove Team' },
    { value: "Position", label: 'Position' },
    { value: "Jersey", label: 'Jersey' },
    { value: "Other", label: 'Other / Comment' },
  ].map(f => ({
    ...f,
    label: f.label ?? entity.fields[f.value].displayName,
    type: f.type ?? 'text',
  }));

  const missingItems = [];
  if (orgPreferences.requiredFields.includes('jerseyNumber') && relationsData?.Jerseys?.length == 0) {
    missingItems.push('Jersey');
  }
  if (orgPreferences.requiredFields.includes('listPositionId') && relationsData?.ListPositions?.length == 0) {
    missingItems.push('Position');
  }

  return (
    <>
      <HmsEntityFormController
        {... { ref, entity, id, isLoading: isLoading || teamsDataLoading, isReloading, isError, isNotFound, data, reload, canCreateChangeRequest, isLocked, canCreateChangeRequest, changeRequestFields, reloadChangeRequests }}
        header={({ isDirty }) =>
          <ApprovalBar
            status={data?.status}
            relationsData={relationsData}
            missingItems={missingItems}
            entity={entity}
            isDirty={isDirty}
            id={id}
            reload={reload}
          />
        }
        form={(
          <>
            <div className="flex flex-col desktop:flex-row w-full">
              <div className="flex items-center justify-center ml-4 mr-6 mb-5 desktop:mb-0 ">
                <EntityLogoEditor entity={entities.Player} id={id} data={data} size={112} disabled={false /* isLocked */} />
              </div>
              <div className="flex-auto">
                <HmsEntityFormGridBasic>
                  <HmsField name="firstName" fieldItemProps={{ disabled: isLocked }} />
                  <HmsField name="lastName" fieldItemProps={{ disabled: isLocked }} />
                  <HmsField name="birthday" fieldItemProps={{ disabled: isLocked }} required={!isAdmin && orgPreferences.requiredFields?.includes("birthday")} />
                  <HmsField name="gender" fieldItemProps={{ disabled: isLocked }} required={!isAdmin && orgPreferences.requiredFields?.includes("gender")} />
                  <HmsField name="stick" fieldItemProps={{ disabled: isLocked }} required={!isAdmin && orgPreferences.requiredFields?.includes("stick")} />
                  <FormSelect name="listPlayerLevelId" label="Player level" options={ListPlayerLevelWithIcons} fieldItemProps={{ disabled: isLocked }}
                    required={orgPreferences.requiredFields?.includes("listPlayerLevelId")} />
                  <HmsField name="status" fieldItemProps={{ disabled: !isAdmin || isLocked }} />
                </HmsEntityFormGridBasic>
              </div>
            </div>

            <h4>Personal</h4>
            <HmsEntityFormGridBasic>
              <HmsField name="height" addonAfter="cm" fieldItemProps={{ disabled: isLocked }} required={!isAdmin && orgPreferences.requiredFields?.includes("height")} />
              <HmsField name="weight" addonAfter="kg" fieldItemProps={{ disabled: isLocked }} required={!isAdmin && orgPreferences.requiredFields?.includes("weight")} />
            </HmsEntityFormGridBasic>
            <HmsEntityFormGridBasic>
              <HmsField name="phone" fieldItemProps={{ disabled: isLocked }} required={!isAdmin && orgPreferences.requiredFields?.includes("phone")} />
              <HmsField name="email" fieldItemProps={{ disabled: isLocked }} required={!isAdmin && orgPreferences.requiredFields?.includes("email")} />
            </HmsEntityFormGridBasic>
            <HmsEntityFormGridBasic>
              <HmsField name="countryBirth" type="COUNTRY_LIST" label="Country of Birth" fieldItemProps={{ disabled: isLocked }} required={!isAdmin && orgPreferences.requiredFields?.includes("countryBirth")} />
              <HmsField name="cityBirth" label="City of Birth" fieldItemProps={{ disabled: isLocked }} required={!isAdmin && orgPreferences.requiredFields?.includes("cityBirth")} />
            </HmsEntityFormGridBasic>
            <HmsEntityFormGridBasic>
              <HmsField name="country" type="COUNTRY_LIST" fieldItemProps={{ disabled: isLocked }} />
              <HmsField name="city" fieldItemProps={{ disabled: isLocked }} />
            </HmsEntityFormGridBasic>
            <h4>External relations</h4>
            <HmsEntityFormGridBasic>
              {isAdmin &&
                <HmsField name="externalId" fieldItemProps={{ disabled: isLocked }} />
              }
              <HmsField name="publicProfileUrl" fieldItemProps={{ disabled: isLocked }} required={!isAdmin && orgPreferences.requiredFields?.includes("publicProfileUrl")} />
            </HmsEntityFormGridBasic>
            {needsToSelectTeam &&
              <>
                <h4>Teams</h4>
                <HmsEntityFormGridBasic>
                  <HmsField
                    name="Teams"
                    label="Team Membership"
                    type="CHECKBOX_GROUP"
                    required={true}
                    fieldItemProps={{ dataType: 'ARRAY' }}
                    options={teamsData?.map((team, idx) => ({
                      value: team.teamId,
                      label: <div className="flex items-center gap-2 my-1 w-full">
                        <EntityLogo entity={team} size={24} useExperimentalBgStyle={true} />
                        <span>{team.name}</span>
                      </div>,
                    }))}
                  />
                </HmsEntityFormGridBasic>
              </>
            }
          </>
        )}
        footer={id != '_new_' && (
          <div className="mt-4">
            <div className="mt-4">
              <HmsTabbedTables
                tables={[
                  ...(data?.status != 'DRAFT' ? [
                    {
                      key: 'Games',
                      title: "Games" + (gamesData ? (' (' + gamesData.length + ')') : ''),
                      children: <PlayerGames gamesData={gamesData} />
                    },
                  ] : []),
                  {
                    key: 'Teams',
                    title: "Teams" + (relationsData?.Teams ? (' (' + relationsData?.Teams?.length + ')') : ''),
                    children: <PlayerTeams data={relationsData?.Teams} sourceEntityId={data?.playerId} sourceEntityData={data} onChange={reloadRelations} />,
                  },
                  {
                    key: 'Jerseys',
                    title: "Jerseys" + (relationsData?.Jerseys ? (' (' + relationsData?.Jerseys?.length + ')') : ''),
                    children: <PlayerJerseys data={relationsData?.Jerseys} sourceEntityId={data?.playerId} sourceEntityData={data} onChange={reloadRelations} teams={relationsData?.Teams} />,
                  },
                  {
                    key: 'Positions',
                    title: "Positions" + (relationsData?.ListPositions ? (' (' + relationsData?.ListPositions?.length + ')') : ''),
                    children: <PlayerPositions data={relationsData?.ListPositions} sourceEntityId={data?.playerId} sourceEntityData={data} onChange={reloadRelations} />,
                  },
                  isAdmin && {
                    key: 'Sponsors',
                    title: "Sponsors" + (relationsData?.Sponsors ? (' (' + relationsData?.Sponsors?.length + ')') : ''),
                    children: <PlayerSponsors data={relationsData?.Sponsors} sourceEntityId={data?.playerId} onChange={reloadRelations} />
                  },
                  isAdmin && {
                    key: 'GaAwardsmes',
                    title: "Awards" + (relationsData?.Awards ? (' (' + relationsData?.Awards?.length + ')') : ''),
                    children: <PlayerAwards data={relationsData?.Awards} sourceEntityId={data?.playerId} onChange={reloadRelations} />
                  },
                  isAdmin && {
                    key: 'Disciplinaries',
                    title: "Disciplinaries" + (relationsData?.Disciplinaries ? (' (' + relationsData?.Disciplinaries?.length + ')') : ''),
                    children: <PlayerDisciplinary data={relationsData?.Disciplinaries} sourceEntityId={data?.playerId} onChange={reloadRelations} />
                  },
                  {
                    key: 'Attachments',
                    title: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                    children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                  },
                  {
                    key: 'ChangeRequests',
                    title: "Change Requests" + (changeRequestsData?.ChangeRequests ? (' (' + (unprocessedChangeRequestCount > 0 ? '' + unprocessedChangeRequestCount + '/' : '') + changeRequestsData?.ChangeRequests?.length + ')') : ''),
                    children: <EntityChangeRequests sourceEntityName={entity.name} sourceEntityId={data?.[entity.primaryKey]} data={changeRequestsData?.ChangeRequests} onChange={reloadChangeRequests} />,
                    showDot: showChangeRequestDot,
                  },
                ].filter(t => t)}
              />
            </div>
          </div>
        )}
      />
    </>
  );
}

export default Player
