import React from "react";

const MonthHeader = ({ title, date, league, className, leagueClassName }) => {
  const dateObj = new Date(date);

  return (
    <section
      className={`mb-6 flex w-full min-w-[300px] items-end justify-between px-2 font-bold italic ${className}`}
    >
      <div>
        <h1 className="text-3xl">{title}</h1>
        {league && (
          <h2 className={`font-league text-xl ${leagueClassName}`}>{league}</h2>
        )}
      </div>
      <div className="text-center not-italic">
        <p className="text-2xl underline underline-offset-4">
          {dateObj.getMonth() + 1}
        </p>
        <p>{dateObj.getFullYear()}</p>
      </div>
    </section>
  );
};

export default MonthHeader;
