import React, { useMemo } from "react";
import GraphWLAgainstLargestSum from "./GraphWLAgainstLargestSum";
import BaseTableRows from "./BaseTableRows";

const TableFaceoffs = ({ data }) => {
  // count needed for the graph
  const largestNumOfFaceOffs = useMemo(() => {
    let maxSum = 0;
    for (const team of data) {
      const currentSum = team.WonFaceOffs + team.LostFaceOffs; // Calculate sum of goals for and against for the current team
      if (currentSum > maxSum) {
        maxSum = currentSum; // Update maxSum if current team's sum is greater
      }
    }
    return maxSum;
  }, [data]);

  return (
    <table className="text-center font-bold">
      <thead>
        <tr className="border-b text-sm">
          <th colSpan="3" />
          <th className="text-base">GP</th>
          <th className="text-base text-green-600">W</th>
          <th className="px-1 text-xs text-green-200">Won FaceOffs</th>
          <th className="px-1 text-xs text-gray-400">Lost FaceOffs</th>
          <th className="border-x border-black bg-white px-1 text-xs text-green-600">
            FaceOff Efficiency %
          </th>
          <th className="px-1 text-xs">Win/Loss FaceOff Parity</th>
        </tr>
      </thead>
      <tbody>
        {data.map((team, index) => (
          <tr key={index}>
            <BaseTableRows index={index} icon={team.Logo} name={team.Name} />
            <td className="px-3">{team.GP}</td>
            <td className="px-3 text-green-600">{team.W}</td>
            <td className="px-3 text-cyan-500">{team.WonFaceOffs}</td>
            <td className="px-3 text-gray-400">{team.LostFaceOffs}</td>
            <td
              className={`border-x border-black px-3 ${index === 0 ? "text-cyan-500" : "text-green-600"}`}
            >
              {team.FaceOffEfficiency}
            </td>
            <td>
              <GraphWLAgainstLargestSum
                largestSum={largestNumOfFaceOffs}
                W={team.WonFaceOffs}
                L={team.LostFaceOffs}
                wClassName="!bg-cyan-400"
                lClassName="!bg-black"
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableFaceoffs;
