import { Input } from 'antd';
import useFetchEntity from 'hooks/useFetchEntity';
import { useMemo } from 'react';

import "@fontsource/audiowide";
import "@fontsource/bebas-neue";
import "@fontsource/covered-by-your-grace";
import "@fontsource/economica";
import "@fontsource/righteous";
import "@fontsource/russo-one";

import DebugPrinter from 'components/Debug/DebugPrinter';
import SizeSelector from 'components/Infographics/Filters/SizeSelector';
import dayjs from 'dayjs';
import useFetchEntities from 'hooks/useFetchEntities';
import usePhaseIdTranslator from 'hooks/usePhaseIdTranslator';
import usePersistentStore from 'store/persistentStore';
import PhaseSelector from './Filters/PhaseSelector';
import { dateFormatIG } from './IGconfig';
import IGHeader from './Parts/IGHeader';
import IGImg from './Parts/IGImg';
import TableOffenceDefence from './Parts/TableOffenceDefence';

const IG7_TeamOffenseVsDefense = ({ showData, exportRef }) => {
  const membership = usePersistentStore((state) => state.get('ig-membership'));
  const setMembership = usePersistentStore((state) => state.set('ig-membership'));
  const bgPictureUrl = usePersistentStore((state) => state.get('ig-tovd-bg-url'));
  const setBgPictureUrl = usePersistentStore((state) => state.set('ig-tovd-bg-url'));
  const size = usePersistentStore((state) => state.get('ig-tovd-size'));
  const setSize = usePersistentStore((state) => state.set('ig-tovd-size'));
  const SIZE_PRESETS = ['PORTRAIT', 'CUSTOM'];
  const DEFAULT_SIZE = { width: 715, height: 1111, preset: 'PORTRAIT' };

  const { phaseIdsAll } = usePhaseIdTranslator(membership);
  const groupId = membership?.groupId;
  const seasonId = membership?.seasonId;

  const { data: groupDetail } = useFetchEntity('Group', groupId, 'Season>Competition');
  const { data: seasonDetail } = useFetchEntity('Season', seasonId, 'Competition');


  const metadata = useMemo(() => {
    return {
      GroupName: groupDetail?.name,
      GroupColor: groupDetail?.color,
      SeasonName: seasonDetail?.name,
      CompetitionName: seasonDetail?.Competition?.name,
      CompetitionLogo:
        seasonDetail?.Competition?.logoUrl?.replace(
          "[size]",
          "cropped_md",
        ),
      Date: dayjs().format(dateFormatIG),
    };
  }, [groupDetail, seasonDetail]);

  const { data: allGamesStatistics } = useFetchEntities(
    phaseIdsAll != null && 'Game',
    {
      filters: { phaseId: phaseIdsAll },
      extraAttributes: ["HomeTeamGoals", "AwayTeamGoals"],
      relations: 'Venue(name,logoUrl),HomeTeam,AwayTeam',
    }
  );

  const TeamStats = useMemo(() => {
    const teamStats = allGamesStatistics
      ?.filter((game) => game.HomeTeam && game.AwayTeam)
      .reduce((acc, game) => {
        if (!acc[game.HomeTeam.name]) {
          acc[game.HomeTeam.name] = {
            Name: game.HomeTeam.name,
            Logo: game.HomeTeam.logoUrl?.replace("[size]", "cropped_md"),
            GP: 0,
            GF: 0,
            GA: 0,
            Chances: 0,
          };
        }
        if (!acc[game.AwayTeam.name]) {
          acc[game.AwayTeam.name] = {
            Name: game.AwayTeam.name,
            Logo: game.AwayTeam.logoUrl?.replace("[size]", "cropped_md"),
            GP: 0,
            GF: 0,
            GA: 0,
            Chances: 0,
          };
        }
        acc[game.HomeTeam.name].GP += 1;
        acc[game.HomeTeam.name].GF += game.HomeTeamGoals;
        acc[game.HomeTeam.name].GA += game.AwayTeamGoals;
        acc[game.HomeTeam.name].Chances +=
          game.HomeTeamGoals + game.AwayTeamSaves;
        acc[game.AwayTeam.name].GP += 1;
        acc[game.AwayTeam.name].GF += game.AwayTeamGoals;
        acc[game.AwayTeam.name].GA += game.HomeTeamGoals;
        acc[game.AwayTeam.name].Chances +=
          game.AwayTeamGoals + game.HomeTeamSaves;
        return acc;
      }, {});

    return teamStats;
  }, [allGamesStatistics]);

  const data = useMemo(() => {
    const data = Object.values(TeamStats ?? {}).map((team) => ({
      ...team,
      Diff: team.GF - team.GA,
      GF_GP: (team.GF / (team.GP || 1)).toFixed(2),
      GA_GP: (team.GA / (team.GP || 1)).toFixed(2),
    }));

    const sorted = data.sort((a, b) => {
      return b.Diff - a.Diff;
    });

    return sorted.slice(0, 15);
  }, [TeamStats]);


  return (
    <>
      <div className="grid grid-cols-[auto,1fr] gap-3 items-center">

        <span>Membership:</span>
        <PhaseSelector
          value={membership}
          onChange={setMembership}
          mandatory={['competitionId', 'seasonId']}
        />

        <span>Background picture url:</span>
        <Input value={bgPictureUrl} onChange={(e) => setBgPictureUrl(e.target.value)} />

        <span>Dimensions:</span>
        <div className="flex gap-2 items-center">
          <SizeSelector
            value={size}
            onChange={setSize}
            presets={SIZE_PRESETS}
            defaultValue={DEFAULT_SIZE}
          />
        </div>

        <div />
      </div>

      {showData && (<DebugPrinter data={[metadata, data]}></DebugPrinter>)}

      {phaseIdsAll &&
        <article
          ref={exportRef}
          className="mx-auto w-[715px] bg-hockey-page-bg font-header text-white"
          style={{
            width: `${size?.width}px`,
            height: `${size?.height}px`,
          }}
        >
          <div className="p-8">
            <IGHeader
              title="Offence vs Defence power"
              league={metadata.GroupName}
              leagueStyle={{ color: metadata.GroupColor ?? '#777777' }}
              small
              date={metadata.Date}
            />
            <TableOffenceDefence data={data} />
          </div>
          <IGImg
            src={bgPictureUrl}
            // src="https://wallup.net/wp-content/uploads/2015/12/235412-nature-landscape-reflection-lake-fall-forest-mist-sunrise-mountain-trees-Canada-clear_sky-sunlight.jpg"
            alt=""
            className="my-24 aspect-[12/5] w-full object-cover"
          />
          <p className="text-center text-gray-400">
            Generated with: HMS | more info at: phmcup.cz/hms
          </p>
        </article>
      }
    </>
  );
};

export default IG7_TeamOffenseVsDefense;
