import moment from 'moment';

export function sorted(arr, comparer = (a, b) => ('' + a).localeCompare(('' + b))) {
    return arr.slice().sort(comparer);
};

export function removeAccents(str) {
    return str
        ?.normalize("NFD") // decompose (e.g. "é" => "é")
        ?.replace(/[\u0300-\u036f]/g, ""); // remove the diacritics
}

export function sumOfProperty(array, property) {
    return array.reduce((total, obj) => {
        const valueToAdd = typeof property === 'function' ? property(obj) : obj[property];
        return total + valueToAdd;
    }, 0);
};

export function unique(arr) {
    return [...new Set(arr)];
}

export const uniqueByProperty = (arr, prop) => {
    return arr && Object.values(
        arr.reduce((uniqueMap, team) => {
            uniqueMap[team[prop]] = team;
            return uniqueMap;
        }, {})
    )
};


export function getWeekDay(date) {
    return ((moment(date).day() + 6) % 7);  // Monday = 0
}

export function JSON_parseSafe(json) {
    try {
        return JSON.parse(json);
    } catch (error) {
        return null;
    }
}

export function cleanFileName(name) {
    const nameNoAccent = removeAccents(name);
    const nameNoSpace = nameNoAccent.replace(/\s+/g, '_');
    const nameNoSpecialChars = nameNoSpace.replace(/[^a-zA-Z0-9_\s\-]/g, '');
    return nameNoSpecialChars;
}

export const removeAttributes = (obj, exceptAttributes = []) => {
    const newObj = {};
    for (const [k, v] of Object.entries(obj)) {
        if (!exceptAttributes.includes(k)) {
            newObj[k] = v;
        }
    }
    return newObj;
}

export function fullName({ FirstName, LastName }) {
    return `${FirstName} ${LastName}`;
}

export function fullNameCamel({ firstName, lastName }) {
    return `${firstName} ${lastName}`;
}

export function defineWinner(str) {
    const [home, away] = str.split(":").map(Number);
    return home > away ? 1 : home < away ? 2 : 0;
}

export function humanizeStringArray(array) {
    if (array === null) return '';
    if (array.length === 0) return '';
    if (array.length === 1) return array[0];
    if (array.length === 2) return `${array[0]} and ${array[1]}`;
    if (array.length > 2) {
        const last = array.pop();
        return `${array.join(', ')}, and ${last}`;
    }
}

