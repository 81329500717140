import useFetchEntity from 'hooks/useFetchEntity';
import { formatDateToLongFormat, formatTimeStringToHHMM } from 'utils/IGdateTimeFunctions';

import "@fontsource/audiowide";
import "@fontsource/bebas-neue";
import "@fontsource/covered-by-your-grace";
import "@fontsource/economica";
import "@fontsource/righteous";
import "@fontsource/russo-one";

import DebugPrinter from 'components/Debug/DebugPrinter';
import SizeSelector from 'components/Infographics/Filters/SizeSelector';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useSearchParamsMulti from 'hooks/useSearchParamsMulti';
import usePersistentStore from 'store/persistentStore';
import GameSelector from './Filters/GameSelector';
import CompetitionLogo from './Parts/CompetitionLogo';
import IGImg from './Parts/IGImg';
import LogoOrTitle from './Parts/LogoOrTitle';
dayjs.extend(customParseFormat);

const IG4_YoutubeThumbnail = ({ showData, exportRef }) => {
  const { useSearchParamSingle } = useSearchParamsMulti();
  const [gameId, setGameId] = useSearchParamSingle('gameId', null);
  const size = usePersistentStore((state) => state.get('ig-youtubethumbnail-size'));
  const setSize = usePersistentStore((state) => state.set('ig-youtubethumbnail-size'));
  const SIZE_PRESETS = ['LANDSCAPE', 'CUSTOM'];
  const DEFAULT_SIZE = { width: 950, height: 534, preset: 'LANDSCAPE' };

  const { data: gameDetail } = useFetchEntity('Game', gameId, 'HomeTeam,AwayTeam,Venue,Phase>Group>Season>Competition');

  const metadata = {
    HomeTeamLogo: gameDetail?.HomeTeam?.logoUrl?.replace(
      "[size]",
      "cropped_md",
    ),
    HomeTeamName: gameDetail?.HomeTeam?.nick,
    AwayTeamLogo: gameDetail?.AwayTeam?.logoUrl?.replace(
      "[size]",
      "cropped_md",
    ),
    AwayTeamName: gameDetail?.AwayTeam?.nick,
    Date: gameDetail?.startDate,
    Time: gameDetail?.startTime,
    VenueName: gameDetail?.Venue?.name,
    VenueLogo: gameDetail?.Venue?.logoUrl?.replace("[size]", "cropped_md"),
    CompetitionName: gameDetail?.Phase?.Group?.Season?.Competition?.name,
    CompetitionLogo:
      gameDetail?.Phase?.Group?.Season?.Competition?.logoUrl?.replace(
        "[size]",
        "cropped_md",
      ),
    SeasonName: gameDetail?.Phase?.Group?.Season?.name,
    PhaseLogo: gameDetail?.Phase?.logoUrl
  };

  return (

    <>
      <div className="grid grid-cols-[auto,1fr] gap-3 items-center">

        <span>Game:</span>
        <GameSelector
          value={gameId}
          onChange={(gid) => { setGameId(gid) }}
        />

        <span>Dimensions:</span>
        <div className="flex gap-2 items-center">
          <SizeSelector
            value={size}
            onChange={setSize}
            presets={SIZE_PRESETS}
            defaultValue={DEFAULT_SIZE}
          />
        </div>

        <div />
      </div>

      {showData && (<DebugPrinter data={[gameDetail, metadata]}></DebugPrinter>)}

      {gameId &&
        <article 
          ref={exportRef} 
          className="mx-auto grid aspect-video w-[950px] grid-cols-3 font-header text-white"
          style={{
            width: `${size?.width}px`,
            height: `${size?.height}px`,
          }}
        >
          <section className="col-span-2 flex flex-col gap-4 bg-hockey-page-bg p-4 font-russo-one uppercase">
            <div className="mt-6 flex items-center justify-center gap-4">
              <IGImg
                src={metadata.HomeTeamLogo}
                alt=""
                className="aspect-square w-64"
              />
              <LogoOrTitle
                logo={metadata.PhaseLogo}
                title="VS"
                className="w-16"
                logoClassName="object-cover scale-[2.5]"
                titleClassName="text-5xl text-center"
              />
              <IGImg
                src={metadata.AwayTeamLogo}
                alt=""
                className="aspect-square w-64"
              />
            </div>
            <p className="text-5xl">{metadata.HomeTeamName}</p>
            <p className="text-right text-5xl">{metadata.AwayTeamName}</p>
            <div className="mt-6 flex w-auto justify-between text-2xl">
              <p>
                {`${formatDateToLongFormat(metadata.Date)}, ${formatTimeStringToHHMM(metadata.Time)}`}
              </p>
              <p>{metadata.VenueName}</p>
            </div>
          </section>
          <section className="flex flex-col items-center gap-4 bg-black p-2 text-white">
            <CompetitionLogo
              src={metadata.CompetitionLogo}
              title={metadata.CompetitionName}
              season={metadata.SeasonName}
            />
            <h2 className="text-8xl">Live</h2>
            <div className="mt-2 text-center">
              <h2 className="text-center text-8xl">Match</h2>
              <h2 className="-mt-3 text-5xl">Broadcast</h2>
            </div>
            <h2 className="text-4xl">from</h2>
            <LogoOrTitle
              logo={metadata.VenueLogo}
              title={metadata.VenueName}
              logoClassName="h-[100px]"
              titleClassName="text-5xl"
            />
          </section>
        </article>
      }
    </>
  );
};

export default IG4_YoutubeThumbnail;
