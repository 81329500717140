import IGImgBase64 from "components/Infographics/Parts/IGImgBase64";
import { useMemo } from "react";
import { fullName } from "../../../utils/oneLiners";
import IGImg from "./IGImg";

const StatRow = ({ label, value }) => (
  <>
    <b>{label}</b>
    <p>{value}</p>
  </>
);

const PlayerAward = ({ data }) => {
  const playerStats = useMemo(
    () => [
      { label: "GP", value: data.Player.GP },
      { label: "G", value: data.Player.G },
      { label: "A", value: data.Player.A },
      { label: "PTS", value: data.Player.PTS },
      { label: "PIM", value: data.Player.PIM },
      { label: "★", value: data.Player.Stars },
    ],
    [data],
  );

  return (
    <section className="grid grid-cols-4 place-items-center justify-around text-3xl">
      <div className="flex w-full items-center justify-end">
        <div className="flex flex-1 flex-col gap-6 text-2xl">
          <b>Sezóna</b>
          <p className="text-2xl">{data.SeasonName}</p>
          {data.DivisionName && <b className="mt-8">Divize</b>}
          {data.DivisionName && <p className="text-lg">{data.DivisionName}</p>}

        </div>
        <IGImg
          src="/images/award-border.png"
          className="h-[450px] w-[25px]"
          alt=""
        />
      </div>

      <div className="col-span-2">
        {data.PlayerPicture && <IGImgBase64
          src={data.PlayerPicture}
          className="h-[450px] w-[450px] object-contain"
          alt=""
        />}
      </div>

      <div className="flex w-full">
        <IGImg
          src="/images/award-border.png"
          className="h-[450px] w-[25px] rotate-180"
          alt=""
        />
        <div className="flex-1">
          <div className="mx-auto my-auto grid h-full w-fit grid-cols-2 place-content-center gap-6">
            {playerStats.map((stat, index) => (
              <StatRow key={index} label={stat.label} value={stat.value} />
            ))}
          </div>
        </div>
      </div>

      <p className="col-span-4 mt-8 text-center text-5xl max-h-[4.5rem]">
        {fullName({
          FirstName: data.PlayerFirstName,
          LastName: data.PlayerLastName,
        })}
        <div className="mt-6">
          <IGImg
            src={data.AwardLogo}
            className="h-[70px] w-[70px] rounded-full"
            alt=""
          />
        </div>
      </p>
      <IGImg
        src={data.TeamLogo}
        className="h-[70px] w-[70px] rounded-full"
        alt=""
      />
      <p className="justify-self-start text-base">{data.TeamName}</p>
      <p className="justify-self-end text-base">{data.AwardName}</p>
    </section>
  );
};

export default PlayerAward;
